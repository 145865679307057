import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ptBr from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        current: 'ptBr',
        locales: { ptBr }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#5586c7',
                blue1: '#5586c7',
                green1: '#56b47f',
                red1: '#dd4449',
                orange1: '#eba83f',

                grey1: '#543e4d',

                darkbackground: '#543e4d',
                darkbackground2: '#20181e',
                
            }
        }
    }
});
