import { render, staticRenderFns } from "./Vagas.vue?vue&type=template&id=722033fc&scoped=true"
import script from "./Vagas.vue?vue&type=script&lang=js"
export * from "./Vagas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722033fc",
  null
  
)

export default component.exports