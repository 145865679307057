<template>
    <div class="menu-page">
        <v-card class="">
            <v-card-title>
                Administração de Contratantes
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field dense class="mb-6" v-model="search" append-icon="mdi-magnify" label="Busque por nome ou documento" clearable outlined single-line hide-details @input="searchOnServer" style="max-width: 500px;"></v-text-field>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                    </template>

                    <template v-slot:[`item.email`]="{ item }">
                        {{ item.user?.email }}
                    </template>

                    <template v-slot:[`item.role`]="{ item }">
                        <span v-if="item.role === 'admin'">Admin do sistema</span>
                        <span v-if="item.role === 'contractor'">Contratante</span>
                        <span v-if="item.role === 'user'">Candidato</span>
                    </template>

                    <template v-slot:[`item.ignore`]="{ item }">
                        {{ item.ignore ? 'Sim' : 'Não' }}
                    </template>

                    <template v-slot:[`item.profile_picture`]="{ item }">
                        <v-avatar size="60" class="my-2">
                            <v-img :src="item.profile_picture" v-if="item.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:60px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top v-if="!item.ignore">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium color="error" @click="object = item; dialog1 = true" v-bind="attrs" v-on="on">{{ item.active ? 'mdi-account-cancel-outline' : 'mdi-account-check-outline' }}</v-icon>
                            </template>
                            <span>Ativar / Desativar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="dialog1" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    {{ object.active ? 'Desativar' : 'Ativar' }} contratante?
                </v-card-title>
                <v-card-text>Tem certeza que deseja {{ object.active ? 'desativar' : 'ativar' }} o contratante <b>{{ object.name }}</b>?</v-card-text>
                <v-card-text v-if="object.active">Esse contratante não terá suas vagas listadas no sistema enquanto estiver desativada.</v-card-text>
                <v-card-text v-if="!object.active">Esse contratante terá suas vagas listadas no sistema.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog1 = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog1 = false; change()">
                        Prosseguir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
let timeout = null;

export default {
    name: "AdminContractorsView",
    data() {
        return {
            objects: [],
            object: {},
            totalObjects: 0,
            search: "",
            loading: true,
            dialog1: false,
            selectedContact: null,
            options: { itemsPerPage: 10 },
            headers: [
                { text: 'Foto', value: 'profile_picture', sortable: false },
                { text: 'Contratante', value: 'name', sortable: false },
                { text: 'E-mail', value: 'email', sortable: false },
                { text: "Documento", value: "document", sortable: false },
                { text: 'Criação', value: 'created', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    watch: {
        options: {
            handler() {
                this.getContractors(this.options);
            },
            deep: true,
        },
    },

    async mounted() {
    },

    methods: {
        async getContractors(options) {
            this.loading = true;
            const resp = await this.$api.getContractors(options);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message.data;
                this.totalObjects = resp.message.total;
            }
        },

        async change() {
            this.object.active = !this.object.active;
            const resp = await this.$api.updateContractor(this.object);

            if (!resp.error && resp.message) {
                this.$emitToastr("success", "Contratante atualizado com sucesso!")
            }
        },

        async searchOnServer() {

            if (timeout) clearTimeout(timeout);
            this.loading = true;

            timeout = setTimeout(() => {
                this.options.page = 1;
                this.options.query = this.search;
                this.loading = false;
                this.getContractors(this.options);
            }, 1000);
        }
    },
};
</script>

<style scoped></style>