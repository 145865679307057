<template>
    <div class="menu-page">
        <v-card class="">
            <v-card-title>
                Administração de Usuários
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field dense class="mb-6" v-model="search" append-icon="mdi-magnify" label="Busque por nome ou email" clearable outlined single-line hide-details @input="searchOnServer" style="max-width: 500px;"></v-text-field>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip small :color="getUserStatus(item.status).color">{{ getUserStatus(item.status).name }}</v-chip>
                    </template>

                    <template v-slot:[`item.contractor`]="{ item }">
                        {{ item.contractor?.name ? item.contractor.name : 'N/A' }}
                    </template>

                    <template v-slot:[`item.role`]="{ item }">
                        <span v-if="item.role === 'admin'">Admin do sistema</span>
                        <span v-if="item.role === 'contractor'">Contratante</span>
                        <span v-if="item.role === 'user'">Candidato</span>
                    </template>

                    <template v-slot:[`item.ignore`]="{ item }">
                        {{ item.ignore ? 'Sim' : 'Não' }}
                    </template>

                    <template v-slot:[`item.profile_picture`]="{ item }">
                        <v-avatar size="60" class="my-2">
                            <v-img :src="item.profile_picture" v-if="item.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:60px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top v-if="!item.ignore">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium color="primary" @click="object = item; dialogUpdateUser = true" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogUpdateUser" max-width="760" class="mx-0">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Editar usuário
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-select chips small-chips outlined v-model="object.role" :items="roles" item-text="name" item-value="id" label="Tipo de conta" hide-details="auto">
                                <template #selection="{ item }">
                                    <v-chip small color="primary">{{ item.name }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field v-model="object.name" label="Nome" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field v-model="object.phone" v-mask="'(##) #####-####'" label="Telefone" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12" v-if="object.role === 'user'">
                            <v-select chips small-chips outlined v-model="object.status" :items="$user_statuses" item-text="name" item-value="id" label="Status" hide-details="auto">
                                <template #selection="{ item }">
                                    <v-chip small :color="item.color">{{ item.name }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field v-model="object.email" label="Email" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-text-field type="password" v-model="object.password" label="Senha" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-switch dense class="pl-2" v-model="object.active" inset label="Ativo"></v-switch>
                        </v-col>
                    </v-row>

                    <v-row v-if="object.cids && object.cids.length > 0">
                        <v-col cols="12" v-for="(item, index) in object.cids" :key="'cids_' + index">
                            <v-card dense outlined>
                                <v-card-title>
                                    <v-icon color="success" class="mr-2">mdi-file-check-outline</v-icon> {{ item.name }}
                                    <v-spacer></v-spacer>
                                    <v-btn class="primary mx-2" @click="$openLink(item.url)">Abrir</v-btn>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-btn color="error lighten-1" outlined @click="dialogUpdateUser = false">
                        Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading" @click="changeUser">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
let timeout = null;

export default {
    name: "AdminUsuariosView",
    data() {
        return {
            objects: [],
            object: {},
            totalObjects: 0,
            search: "",
            loading: true,
            dialog1: false,
            dialogUpdateUser: false,
            selectedContact: null,
            options: { itemsPerPage: 10 },
            roles: [
                { id: 'user', name: 'Candidato' },
                { id: 'contractor', name: 'Contratante' }
            ],
            headers: [
                { text: 'Foto', value: 'profile_picture', sortable: false },
                { text: 'Nome', value: 'name', sortable: false },
                { text: 'E-mail', value: 'email', sortable: false },
                { text: "Tipo", value: "role", sortable: false },
                { text: "Status", value: "status", sortable: false },
                { text: "Contratante", value: "contractor", sortable: false },
                { text: 'Criação', value: 'created', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }

    },

    watch: {
        options: {
            handler() {
                this.getUsers(this.options);
            },
            deep: true,
        },
    },

    async mounted() {
    },

    methods: {
        getUserStatus(id) {
            return this.$user_statuses.find(x => x.id === id);
        },

        async getUsers(options) {
            this.loading = true;
            const resp = await this.$api.getUsers(options);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message.data;
                this.totalObjects = resp.message.total;
            }
        },

        async changeUser() {
            this.loading = true;
            const resp = await this.$api.updateUser(this.object);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.$emitToastr("success", "Usuário atualizado com sucesso!");
                this.dialogUpdateUser = false;
            }
        },

        async searchOnServer() {

            if (timeout) clearTimeout(timeout);
            this.loading = true;

            timeout = setTimeout(() => {
                this.options.page = 1;
                this.options.query = this.search;
                this.loading = false;
                this.getUsers(this.options);
            }, 1000);
        }
    },
};
</script>

<style scoped></style>