<template>
    <div class="menu-page">
        <div>
            <v-alert class="fade" type="warning" v-if="currentUser.status != 'approved'">
                Para acessar todos os recursos da plataforma é necessário enviar sua documentação (Classificação Estatística Internacional de Doenças e Problemas Relacionados com a Saúde - CID). Depois de enviados, seus documentos serão avaliados em até 3 dias úteis, e então você poderá se
                candidatar a qualquer uma das vagas disponíveis.
            </v-alert>
        </div>

        <v-card class="mb-4" v-if="currentUser.status !== 'approved'">
            <v-card-title>
                Documentação
            </v-card-title>
            <v-card-subtitle>
                Envie aqui toda sua documentação (CID) e clique em salvar. Seu perfil será analisado em até 3 dias úteis.
            </v-card-subtitle>

            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-file-input accept="application/pdf" v-model="file" label="Adicionar novo documento" dense outlined hide-details="auto" :loading="loading" @change="fileChanged">Novo documento</v-file-input>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="filename" class="mx-2" label="Nome do documento" outlined dense hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-btn outlined color="primary" :loading="loading" @click="uploadFile">Enviar documento</v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="currentUser.cids && currentUser.cids.length > 0">
                    <v-col cols="12" v-for="(item, index) in currentUser.cids" :key="'cids_' + index">
                        <v-card dense outlined>
                            <v-card-title>
                                <v-icon color="success" class="mr-2">mdi-file-check-outline</v-icon> {{ item.name }}
                                <v-spacer></v-spacer>
                                <v-btn class="primary mx-2" @click="$openLink(item.url)">Abrir</v-btn>
                                <v-btn class="mx-2" color="error" outlined @click="removeDocument(index)">Apagar</v-btn>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-4" v-if="user.public_profile">
            <v-card-title>
                Meu perfil
            </v-card-title>

            <v-card-subtitle>
                Crie uma página de perfil incrível e compartilhe com empresas e vagas de trabalho
            </v-card-subtitle>

            <v-card-title>Dados de cadastro</v-card-title>
            <v-card-text>
                <v-row class="align-center">
                    <v-col cols="12" lg="2" md="2" sm="12">
                        <v-avatar size="120">
                            <v-img :src="currentUser.profile_picture" v-if="currentUser.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon style="font-size:120px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                    </v-col>

                    <v-col cols="12" lg="10" md="10" sm="12">
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.name" disabled label="Nome" outlined dense hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.email" disabled label="E-mail" outlined dense hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.phone" disabled label="Telefone" outlined dense hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.city" disabled label="Cidade" outlined dense hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="currentUser.state" disabled label="Estado" outlined dense hide-details="auto"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
            </v-card-text>

            <v-card-title>Dados de perfil</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="user.public_profile.bio" rows="5" label="Mini biografia" outlined dense hide-details="auto"></v-textarea>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.whatsapp" v-mask="'(##) #####-####'" label="Whatsapp" outlined dense hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.linkedin" label="Perfil no linkedin (link completo)" outlined dense hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.instagram" label="Instagram (link completo)" outlined dense hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="user.public_profile.website" label="Website pessoal (se possuir)" outlined dense hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>

                <v-divider class="mt-4"></v-divider>
            </v-card-text>


            <v-card-title>Portfólio</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn class="primary" @click="newProject">Adicionar um projeto</v-btn>
                    </v-col>

                    <v-col cols="12" v-for="(item, index) in user.public_profile.portfolio" :key="`proj_${index}`">
                        <v-row>
                            <v-col cols="12">
                                <span class="text-h6">Projeto {{ index + 1 }}</span>
                            </v-col>
                            <v-col cols="12">
                                <div class="d-flex align-center">
                                    <v-avatar size="100" rounded>
                                        <v-img :src="item.image" v-if="item.image" />
                                        <div v-else class="w-full h-full primary d-flex justify-center">
                                            <v-icon style="font-size:100px">mdi-image</v-icon>
                                        </div>
                                    </v-avatar>
                                    <v-file-input accept=".jpeg,.jpg,.png" v-model="item.file" label="Imagem do projeto" dense outlined hide-details="auto" :loading="loading" @change="uploadImage($event, item)">Imagem</v-file-input>
                                </div>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12" class="mt-1">
                                <v-text-field v-model="item.name" label="Nome do projeto" outlined dense hide-details="auto" @change="$forceUpdate()"></v-text-field>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-text-field v-model="item.website" label="Website do projeto" outlined dense hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea v-model="item.description" rows="3" label="Descrição do projeto" outlined dense hide-details="auto"></v-textarea>
                            </v-col>

                            <v-col cols="12">
                                <v-spacer></v-spacer>
                                <v-btn small right outlined color="error lighten-1" @click="selectedProjectIndex = index; dialogDeleteProject = true">Apagar projeto {{ item.name }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4"></v-divider>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-title>Configurações de exibição</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-switch dense class="pl-2" v-model="user.public_profile.show_email" inset label="Exibir e-mail?"></v-switch>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-switch dense class="pl-2" v-model="user.public_profile.show_whatsapp" inset label="Exibir Whatsapp?"></v-switch>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-switch dense class="pl-2" v-model="user.public_profile.active" inset label="Perfil publicamente visível"></v-switch>
                    </v-col>
                </v-row>

                <v-divider class="mt-4"></v-divider>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary my-4 mr-2" large @click="saveProfile">Salvar perfil</v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="dialogDeleteProject" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar projeto
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar este projeto?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogDeleteProject = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialogDeleteProject = false; user.public_profile.portfolio.splice(selectedProjectIndex, 1); selectedProjectIndex = -1">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "PerfilView",

    data() {
        return {
            file: null,
            filename: '',
            user: {
                name: '',
                email: '',
                profile_picture: '',
                public_profile: { portfolio: [], active: true, whatsapp: '' }
            },
            loading: false,
            newDocument: { name: '', url: '' },
            selectedProjectIndex: -1,
            dialogDeleteProject: false
        };
    },
    computed: {
        currentUser: {
            get() {
                return this.$store.state.currentUser;
            },
            set(value) {
                this.$store.commit('setCurrentUser', value);
            }
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            const user = await this.$api.getRemoteUser();
            this.user = user;
            if (this.user.role == 'user') {
                if (!this.user.public_profile) this.user.public_profile = { portfolio: [], active: true, whatsapp: '' };
            }
        },

        fileChanged(data) {
            this.newDocument = { file: data };
        },

        async uploadFile() {
            if (!this.file) return this.$emitToastr("error", "É necessário selecionar um arquivo");
            if (!this.filename) return this.$emitToastr("error", "É necessário dar um nome para o arquivo");

            this.loading = true;
            const resp = await this.$api.uploadDocument(this.file);
            this.loading = false;

            if (!resp.error && resp.message) {
                let user = this.currentUser;
                if (!user.cids) user.cids = [];
                user.cids.push({
                    name: this.filename,
                    url: resp.message
                });

                this.loading = true;
                const resp2 = await this.$api.updateUser(user);
                this.loading = false;

                if (!resp2.error && resp2.message) {
                    this.file = null;
                    this.filename = '';
                    this.$emitToastr("success", "Documento enviado com sucesso!");
                    window.location.reload();
                }
            }
        },

        async removeDocument(index) {
            let user = this.currentUser;
            if (!user.cids) user.cids = [];

            user.cids.splice(index, 1)

            this.loading = true;
            const resp2 = await this.$api.updateUser(user);
            this.loading = false;

            if (!resp2.error && resp2.message) {
                this.file = null;
                this.filename = '';
                this.$emitToastr("success", "Documento apagado com sucesso");
                window.location.reload();
            }
        },

        newProject() {
            if (!this.user.public_profile) this.user.public_profile = {};
            if (!this.user.public_profile.portfolio) this.user.public_profile.portfolio = [];
            this.user.public_profile.portfolio.push({ name: "Nome do projeto", description: "Descrição do projeto", website: "" });
            this.$forceUpdate();
        },

        async uploadImage(file, item) {
            if (!file) {
                item.image = null;
                this.$forceUpdate();
                return;
            }

            const resp = await this.$api.uploadImage(file);

            if (resp.error) {
                this.$emitToastr('error', resp.message);
                return
            }

            item.image = resp.message;
            this.$forceUpdate();
        },

        async saveProfile() {
            if (!this.user.public_profile) return this.$emitToastr("error", "Perfil sem dados");
            if (this.user.public_profile.portfolio && this.user.public_profile.portfolio.length > 0) {
                let error = false;
                this.user.public_profile.portfolio.forEach(i => { if (!i.name) error = true; });
                if (error) return this.$emitToastr("error", "Nome é um campo obrigatório no cadastro de projetos do portfólio.");
            }

            this.loading = true;
            const resp2 = await this.$api.updateUser(this.user);
            this.loading = false;

            if (!resp2.error && resp2.message) {
                this.$emitToastr("success", "Perfil atualizado com sucesso");
            }
        },
    },
};
</script>