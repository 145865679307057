<template>
    <div class="lp-container">
        <section class="section-01">
            <img src="@/assets/lp_horizontal_logo.png" class="section-01-logo">
            <img src="@/assets/lp_vertical_logo_mobile.png" class="section-01-logo-mobile">

        </section>

        <section class="section-02">
            <center><v-img src="@/assets/lp_btn_1.png" width="80"></v-img></center>

            <v-row>
                <v-col cols="12" class="text-center">
                    <img src="@/assets/lp_section2_image.png" class="section-02-image">
                </v-col>
            </v-row>

            <v-row class="pl-16">
                <v-col cols="12" lg="6" md="6" sm="12" class="d-flex align-center justify-center">
                    <span style="max-width: 400px;" class="white--text">Oferece uma <b>solução abrangente</b> para profissionais criativos que possuem algum tipo de deficiência e atuam ou desejam atuar no mercado da Cultura e Economia Criativa.</span>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="12" lg="6" md="6" sm="12" align="center">
                    <img class="mobile-img" src="@/assets/lp_image_02.png">
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="12" class="d-flex align-center justify-center">
                    <span style="max-width: 400px;" class="white--text mb-8">Proporcionamos aos nossos usuários a conveniência de <b>acessar cursos adaptados</b>, descobrir oportunidades de trabalho temporário em projetos culturais e exibir seus portfólios criativos em um ambiente digital totalmente
                        acessível.</span>
                </v-col>


            </v-row>


        </section>

        <section class="section-03">
            <v-row justify="center" style="max-width: 800px; margin: auto">
                <v-col cols="12" lg="6" md="6" sm="12" class="">
                    <div class="section-3-text-col">
                        <div style="font-weight: bold; font-size: 24px;" class="linear-gradient-text">
                            Com uma:
                        </div>

                        <br>

                        <div style="color: #0075FF; font-weight: 500;">
                            ⬤ Interface intuitiva <br>
                            ⬤ Recursos de Suporte Especializado<br>
                            ⬤ E o compromisso com a inclusão e diversidade<br>
                        </div>

                        <br>

                        <div style="font-weight: 500;">
                            Nos <b>dedicamos a facilitar</b> a jornada empreendedora de cada usuário, promovendo o <b>crescimento profissional e o sucesso no mercado cultural</b> em condição de igualdade com pessoas sem deficiência.
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12">
                    <img src="@/assets/lp_image_01.png" class="section-03-image mobile-img">
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="mt-4">
                    <div style="max-width: 600px; margin: auto;text-align: center; font-size: 22px; font-weight: bold;" class="linear-gradient-text">
                        Estamos comprometidos com os Objetivos de Desenvolvimento Sustentável da ONU:
                    </div>
                </v-col>
            </v-row>


            <v-row class="section-3-card-row" style="padding-top: 60px;">
                <v-col cols="12" lg="4" md="4" sm="12" class="mb-6">
                    <v-card flat>
                        <div style="text-align: center; height: 50px;"><img src="@/assets/section-3-card-1.png" class="section-3-card-image"></div>
                        <v-card-text class="pb-12">
                            <div style="color: #db464a; font-size: 20px; font-weight: bold; text-align: center">Educação de Qualidade</div>
                            <div class="mt-2 text-center">Aumentar substancialmente o número de jovens e adultos que tenham habilidades relevantes, inclusive competências técnicas e profissionais, para emprego, trabalho decente e empreendedorismo</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="12" class="mb-6">
                    <v-card flat>
                        <div style="text-align: center; height: 50px;"><img src="@/assets/section-3-card-2.png" class="section-3-card-image"></div>
                        <v-card-text class="pb-12">
                            <div style="color: #db464a; font-size: 20px; font-weight: bold; text-align: center">Trabalho decente e Crescimento Econômico</div>
                            <div class="mt-2 text-center">Alcançar o emprego pleno e produtivo e trabalho decente para todas as mulheres e homens, inclusive para os jovens e as pessoas com deficiência, e remuneração igual para trabalho de igual valor</div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="12" class="mb-6">
                    <v-card flat>
                        <div style="text-align: center; height: 50px;"><img src="@/assets/section-3-card-3.png" class="section-3-card-image"></div>
                        <v-card-text class="pb-12">
                            <div style="color: #db464a; font-size: 20px; font-weight: bold; text-align: center">Redução das Desigualdades</div>
                            <div class="mt-2 text-center">Empoderar e promover a inclusão social, econômica e política de todos, independentemente da idade, gênero, deficiência, raça, etnia, origem, religião, condição econômica ou outra</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </section>


        <section class="section-04">
            <div class="gallery">
                <v-img src="@/assets/galeria_1.png" aspect-ratio="1" contain class="mx-2 my-4"></v-img>
                <v-img src="@/assets/galeria_2.png" aspect-ratio="1" contain class="mx-2 my-4"></v-img>
                <v-img src="@/assets/galeria_3.png" aspect-ratio="1" contain class="mx-2 my-4"></v-img>
                <v-img src="@/assets/galeria_4.png" aspect-ratio="1" contain class="mx-2 my-4"></v-img>
                <v-img src="@/assets/galeria_5.png" aspect-ratio="1" contain class="mx-2 my-4"></v-img>
            </div>
        </section>

        <section class="section-05">
            <v-row>
                <v-col cols="12" sm="12">
                    <div class="section-5-col col-left">Somos a <b>primeira e única plataforma digital</b> inteiramente dedicada favorecer a autonomia, <span style="font-weight: bold; color: #0075ff">dar visibilidade e qualificar profissionais com deficiência</span> que atuam ou aspiram atuar no
                        mercado da Economia Criativa e Cultura. </div>

                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col cols="12">
                    <div class="section-5-col col-right">Não é apenas mais uma plataforma digital, é um <span style="font-weight: bold; color: #db464a">movimento social anti capacitismo</span> que transforma a maneira como pessoas com deficiência participam e prosperam no mercado da Economia
                        Criativa e Cultura.</div>
                </v-col>
            </v-row>

            <v-row style="width: 90vw; max-width: 1000px; margin: auto" class="my-10">
                <v-card flat>
                    <v-card-text>
                        Pautamos a construção da <span style="font-weight: bold; color: #66c08c">plataforma no compromisso inabalável com a acessibilidade</span>, uma comunidade de suporte robusta, parcerias estratégicas e uma estratégia de inovação contínua.
                    </v-card-text>
                </v-card>
            </v-row>
        </section>

        <section class="section-06">
            <h2 class="mt-4 white--text text-center section-06-title">Entenda como<br> funciona a plataforma</h2>

            <v-row>
                <v-col cols="12" lg="6" md="6" class="d-flex justify-center">
                    <v-card flat color="#db464a" style="max-width: 400px;">
                        <v-card-text class="white--text">
                            <h1>Autonomia:</h1>
                        </v-card-text>
                        <v-card-text class="white--text">
                            A plataforma oferece uma área de classificados de vagas onde haverá a divulgação de oportunidades de trabalho temporário e/ou colaborações em projetos culturais. Os proponentes divulgam suas vagas disponíveis e perfis desejados e a plataforma oferece os perfis
                            cadastrados.
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="6" md="6" class="d-flex justify-center">
                    <v-card flat color="#f49500" style="max-width: 400px;">
                        <v-card-text class="white--text">
                            <h1 class="text-right">Visibilidade:</h1>
                        </v-card-text>
                        <v-card-text class="white--text text-right">
                            Um espaço onde os profissionais com deficiência podem exibir seus trabalhos criativos e projetos realizados. Funcionando como um portfólio, esta área representa uma vitrine profissional digital e acessível para potenciais clientes e parceiros.
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" lg="6" md="6" class="d-flex justify-center">
                    <v-card flat color="#66c08c" style="max-width: 400px;">
                        <v-card-text class="white--text">
                            <h1>Qualificação:</h1>
                        </v-card-text>
                        <v-card-text class="white--text">
                            Estamos falando de um ambiente de aprendizado onde os usuários têm acesso a uma variedade de cursos livres, todos adaptados para diferentes tipos de deficiência, cobrindo temas e trilhas relacionados à Economia Criativa e Cultura.
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="6" md="6" class="d-flex justify-center">
                    <v-card flat color="#3b95ff" style="max-width: 400px;">
                        <v-card-text class="white--text">
                            <h1 class="text-right">Comunidade:</h1>
                        </v-card-text>
                        <v-card-text class="white--text text-right">
                            Um espaço de conexão e apoio mútuo, onde os usuários podem interagir, compartilhar experiências, desafios e conquistas. A plataforma promove uma comunidade inclusiva e solidária, onde cada membro pode aprender, crescer e colaborar.
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="pa-12 mt-6">
                <v-col cols="12">
                    <v-card style="max-width: 800px; margin:auto">
                        <v-card-title style="word-break: normal;">Quer ser o primeiro a saber quando a plataforma for lançada?</v-card-title>
                        <v-card-subtitle>Cadastre seu e-mail e fique por dentro das novidades.</v-card-subtitle>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" lg="6" md="6" sm="12" class="d-flex">
                                    <v-text-field dense v-model="name" label="Nome" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="6" md="6" sm="12" class="d-flex">
                                    <v-text-field dense v-model="whatsapp" v-mask="'(##) #####-####'" label="Whatsapp" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12"  sm="12" class="d-flex">
                                    <v-text-field dense v-model="email" label="E-mail" outlined hide-details="auto"></v-text-field>
                                </v-col>

                                <v-col cols="12"  sm="12" class="d-flex">
                                    <v-btn color="primary" block :loading="loading" @click="sendData">Enviar</v-btn>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </section>

        <section class="section-07">
            <div class="footer-div">
                <img class="section-07-logo" src="@/assets/lp_horizontal_logo.png">
            </div>
        </section>
    </div>
</template>

<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "HomeView",

    data() {
        return {
            email: '',
            name: '',
            whatsapp: '',
            loading: false
        };
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        }
    },

    methods: {
        async sendData() {
            if (!this.$validateEmail(this.email)) return this.$emitToastr("error", "E-mail inválido");
            if (!this.whatsapp) return this.$emitToastr("error", "Por favor preencha o campo Whatsapp");
            if (!this.name) return this.$emitToastr("error", "Por favor preencha o campo Nome");

            this.loading = true;
            const resp = await this.$api.sendUserData(this.email, this.name, this.whatsapp);
            this.loading = false;
            this.email = '';
            this.name = '';
            this.whatsapp = '';
            if (!resp.error && resp.message) {
                return this.$emitToastr("success", "E-mail cadatrado com sucesso! Em breve você receberá novidades sobre o lançamento da plataforma.");
            }
            
        }
    },
};
</script>

<style scoped>
.lp-container {
    max-width: 100vw;
    overflow-x: hidden;
}

.section-01 {
    background-color: #0075ff;
    background-image: url("@/assets/lp_background_01.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-01-logo {
    width: 65%;
    max-width: 800px;
    margin-left: 20px;
}

.section-01-logo-mobile {
    display: none;
}

.section-02 {
    border-top: 4px solid white;
    background-color: #0075ff;
    background-image: url('@/assets/lp_background_02_1.png'), url('@/assets/lp_background_02_2.png'), radial-gradient(circle at center, #0067dd, #0075ff);
    background-position: center bottom, right center, center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 650px;
    width: 100%;
}

.section-02-image {
    margin-top: 20px;
    width: 50%;
    max-width: 400px;
}

.section-02-image2 {
    text-align: right;
}

.section-03 {
    background-color: #e6e6e6;
    background-image: url("@/assets/lp_background_03.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 600px;
    width: 100%;
    padding-top: 30px;
}

.section-03-image {
    max-width: 450px;
}

.section-3-text-col {
    max-width: 400px;
    margin: auto;
}

.section-3-card-row {
    width: 95vw;
    max-width: 1200px;
    margin: auto;
}

.section-3-card-image {
    width: 80px;
    transform: translate(0px, -40px);
}


.section-04 {
    background-color: #db464a;

}

.gallery {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.section-05 {
    background-color: #e6e6e6;
    min-height: 400px;
    padding-top: 30px;
}

.section-5-col {
    max-width: 400px;
    margin: auto;
}

.col-left {
    text-align: left;
    transform: translate(-150px);
}

.col-right {
    text-align: right;
    transform: translate(150px);
}

.section-06 {
    background-image: url('@/assets/lp_background_3.png'), radial-gradient(circle at center, #0067dd, #0075ff);
    background-position: center center, center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 600px;
    width: 100vw;
    padding-top: 30px;
}

.section-06-title {
    margin-bottom: 60px;
}

.section-07 {
    background-image: url('@/assets/lp-footer.png'), radial-gradient(circle at center, #0067dd, #0075ff);
    background-position: center bottom, center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    width: 100vw;
    padding-top: 30px;
    display: flex;
    align-items: end;
}

.section-07-logo {
    width: 100vw;
    max-width: 800px;
    padding-bottom: 60px;
}

.linear-gradient-text {
    background: -webkit-linear-gradient(left, #0075FF, #004699);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


@media only screen and (max-width: 960px) {
    .mobile-img {
        max-width: 90vw;
    }

    .section-01 {
        background-image: url("@/assets/lp_background_01_mobile.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        padding-right: 5%;
        padding-left: 5%;
    }

    .section-01-logo {
        display: none;
    }

    .section-01-logo-mobile {
        display: block;
        margin: auto;
        width: 80vw;
        max-width: 600px;
    }

    .section-02 {
        padding-right: 5%;
        padding-left: 5%;
    }

    .section-03 {
        padding-right: 5%;
        padding-left: 5%;
    }

    .section-03-image {
        max-width: 75vw;
    }

    .section-3-card-row {
        width: auto;
    }

    .section-05 {
        padding-right: 5%;
        padding-left: 5%;
    }

    .section-5-col {
        justify-content: center;
        text-align: center;
        transform: none;
    }

    .section-06 {
        padding-right: 5%;
        padding-left: 5%;
    }
}
</style>