import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Conta from "../views/Conta.vue";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Cursos from "../views/Cursos.vue";
import Vagas from "../views/Vagas.vue";
import MinhaEmpresa from "../views/MinhaEmpresa.vue";
import MinhasVagas from "../views/MinhasVagas.vue";
import AdminUsuarios from "../views/AdminUsuarios.vue";
import AdminEmpresas from "../views/AdminEmpresas.vue";
import AdminCursos from "../views/AdminCursos.vue";
import Perfil from "../views/Perfil.vue";
import PerfilPublico from "../views/PerfilPublico.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "fullscreen",
    },
  },

  {
    path: "/perfil/:user_id",
    name: "perfil-publico",
    component: PerfilPublico,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/conta",
    name: "Conta",
    component: Conta,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/meu-perfil",
    name: "Perfil",
    component: Perfil,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/cursos",
    name: "Cursos",
    component: Cursos,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/vagas",
    name: "Vagas",
    component: Vagas,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/minha-empresa",
    name: "MinhaEmpresa",
    component: MinhaEmpresa,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/minhas-vagas",
    name: "MinhasVagas",
    component: MinhasVagas,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/admin-usuarios",
    name: "AdminUsuarios",
    component: AdminUsuarios,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/admin-empresas",
    name: "AdminEmpresas",
    component: AdminEmpresas,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/admin-cursos",
    name: "AdminCursos",
    component: AdminCursos,
    meta: {
      layout: "menu",
    },
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/", "/login", "/password-recovery"];
  const authRequired = !publicPages.includes(to.path) && !(to.path).includes("/perfil/");
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
