import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/lib/Api'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentUser: null,
        pageTitle: 'PCD Protagoniza Cultura',
        pageLoading: false
    },
    mutations: {
        setCurrentUser(state, payload) {
            state.currentUser = payload
        },
        setPageTitle(state, payload) {
            state.pageTitle = payload

            document.title = payload || 'PCD Protagoniza Cultura'
        },
        setPageLoading(state, payload) {
            state.pageLoading = payload
        }
    },
    actions: {
        async loadCurrentUser({ commit }) {
            const user = await Api.getRemoteUser();

            commit('setCurrentUser', user)
        },
    },
    getters: {
        isAdmin(state) {
            return !!state.currentUser?.admin
        }
    }
})