<template>
    <div class="menu-page">
        <v-card class="">
            <v-card-title>
                Minhas vagas
            </v-card-title>

            <v-card-text>
                <v-row class="mb-4">
                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Busque por título" clearable outlined single-line hide-details style="max-width: 500px;"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12" class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="newObject">Criar nova vaga</v-btn>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="objects" :loading="loading">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                    </template>

                    <template v-slot:[`item.job_type`]="{ item }">
                        {{ getJobTypeName(item.job_type) }}
                    </template>


                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click="confirmDeleteQuickMessage(item)">
                                    <v-icon medium class="mr-2" color="error lighten-1" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Apagar</span>
                        </v-tooltip>

                        <v-tooltip top v-if="!item.ignore">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon medium color="primary" @click="object = item; dialogCreateOrUpdate = true" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogCreateOrUpdate" persistent max-width="900" class="mx-0">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    {{ object._id ? 'Editar' : 'Criar' }} vaga
                </v-card-title>

                <v-card-subtitle style="word-break: break-word;">
                    Aqui você poderá criar ou editar suas vagas de trabalho
                </v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="8" md="8" sm="12">
                            <v-text-field v-model="object.title" label="Título da vaga" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="4" md="4" sm="12">
                            <v-select chips small-chips outlined v-model="object.job_type" :items="$job_types" item-text="name" item-value="id" label="Tipo de trabalho" hide-details="auto">
                                <template #selection="{ item }">
                                    <v-chip small color="primary">{{ item.name }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="object.about_contractor" rows="3" label="Sobre o contratante (a empresa ou você caso seja pessoa física)" outlined dense hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="object.about_job" rows="3" label="Sobre a vaga" outlined dense hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="object.activities" rows="3" label="Atividades pertinentes da vaga" outlined dense hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="object.requirements" rows="3" label="Requisitos esperados do candidato" outlined dense hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="object.benefits" rows="3" label="Benefícios da vaga" outlined dense hide-details="auto"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea v-model="object.additional_information" rows="3" label="Informações adicionais" outlined dense hide-details="auto"></v-textarea>
                        </v-col>

                    </v-row>

                    <v-row v-if="object.cids && object.cids.length > 0">
                        <v-col cols="12" v-for="(item, index) in object.cids" :key="'cids_' + index">
                            <v-card dense outlined>
                                <v-card-title>
                                    <v-icon color="success" class="mr-2">mdi-file-check-outline</v-icon> {{ item.name }}
                                    <v-spacer></v-spacer>
                                    <v-btn class="primary mx-2" @click="$openLink(item.url)">Abrir</v-btn>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-btn color="error lighten-1" outlined @click="dialogCreateOrUpdate = false">
                        Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading" @click="save">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar vaga
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a vaga <b>{{ object.title }}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteJob()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
export default {
    name: "ContractorJobsView",
    data() {
        return {
            objects: [],
            object: {},
            search: "",
            loading: true,
            dialogCreateOrUpdate: false,
            deleteDialog: false,
            headers: [
                { text: 'Título', value: 'title', sortable: false },
                { text: 'Tipo', value: 'job_type', sortable: false },
                { text: 'Criação', value: 'created', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }

    },

    async mounted() {
        this.getObjects();
    },

    methods: {
        async getObjects() {
            this.loading = true;
            const resp = await this.$api.getJobsByContractor();
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        newObject() {
            this.object = {};
            this.dialogCreateOrUpdate = true;
        },

        confirmDeleteQuickMessage(item) {
            this.object = item;
            this.deleteDialog = true;
        },

        getJobTypeName(id) {
            return this.$job_types.find(x => x.id == id)?.name || "Não encontrado";
        },

        async save() {
            if (!this.object.title) return this.$emitToastr("error", "Título da vaga obrigatório");
            if (!this.object.job_type) return this.$emitToastr("error", "Tipo da vaga obrigatório");
            if (!this.object.about_job) return this.$emitToastr("error", "Sobre a vaga é um campo obrigatório");

            this.loading = true;
            const resp = await this.$api.createOrUpdateJob(this.object);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.$emitToastr("success", "Vaga atualizada com sucesso!");
                this.dialogCreateOrUpdate = false;
                this.object = {};
                this.getObjects()
            }
        },

        async deleteJob() {
            this.loading = true;
            this.object.filed = true;
            const resp = await this.$api.createOrUpdateJob(this.object);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.$emitToastr("success", "Vaga apagada com sucesso!");
                this.deleteDialog = false;
                this.object = {};
                this.getObjects()
            }
        }
    },
};
</script>

<style scoped></style>