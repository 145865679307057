import { render, staticRenderFns } from "./AdminUsuarios.vue?vue&type=template&id=bb80a38a&scoped=true"
import script from "./AdminUsuarios.vue?vue&type=script&lang=js"
export * from "./AdminUsuarios.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb80a38a",
  null
  
)

export default component.exports