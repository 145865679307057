<template>
    <div class="menu-page">
        <v-card class="mb-4" max-width="1000">
            <v-card-title>
                Minha empresa
            </v-card-title>

            <v-card-text>
                <v-form @submit.prevent="save">
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" sm="4" md="3" lg="2" class="d-flex justify-center">
                                    <v-avatar size="120">
                                        <v-img :src="userImage" v-if="userImage" />
                                        <div v-else class="w-full h-full primary d-flex justify-center">
                                            <v-icon style="font-size:120px">mdi-account</v-icon>
                                        </div>
                                    </v-avatar>
                                </v-col>

                                <v-col cols="12" sm="auto" class="d-flex align-center text-center text-sm-left">
                                    <div>
                                        <v-btn color="primary" class="ma-1" @click="pickFile">Alterar Foto</v-btn>
                                        <v-btn color="error" class="ma-1" outlined @click="removeImage">Remover Foto</v-btn>

                                        <div class="w-full mt-4">
                                            Imagens permitidas: JPG, PNG e com tamanho máximo de 2MB.
                                        </div>
                                    </div>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field v-model="object.name" label="Nome da empresa" outlined hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-select chips small-chips outlined v-model="object.segment" :items="$segments" item-text="name" item-value="name" label="Segmento de atuação" hide-details="auto">
                                        <template #selection="{ item }">
                                            <v-chip small color="primary"><v-icon small class="mr-2">{{ item.icon }}</v-icon>{{ item.name }}</v-chip>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field v-model="object.document" v-mask="'##.###.###/####-##'" label="Documento" outlined hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="object.description" rows="4" label="Descrição da empresa" placeholder="Escreva aqui uma descrição de até 5 linhas sobre essa empresa." outlined hide-details="auto" />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" class="d-flex">
                            <v-spacer />
                            <v-btn color="error" class="mr-2" outlined @click="load" :disabled="saving">Cancelar</v-btn>
                            <v-btn color="primary" type="submit" :loading="saving">Salvar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "ContaView",

    data() {
        return {
            object: {
                profile_picture: ''
            },
            saving: false,
            image: {
                file: null,
                preview: null,
            },
            updatePasswordForm: {
                showPassword: false,
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
        };
    },
    computed: {
        userImage() {
            return this.image.preview || this.object.profile_picture;
        },
        currentUser: {
            get() {
                return this.$store.state.currentUser;
            },
            set(value) {
                this.$store.commit('setCurrentUser', value);
            }
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            const resp = await this.$api.getUserContractor();

            if (!resp.error && resp.message) this.object = resp.message;

            this.image.preview = null;
            this.image.file = null;
        },

        async pickFile() {
            const input = document.createElement('input')

            input.type = 'file'
            input.accept = 'image/jpeg, image/png'

            input.onchange = async (e) => {
                const file = e.target.files[0]

                this.setImage(file)
            }

            input.click()
        },
        removeImage() {
            this.image.file = null
            this.object.profile_picture = null
        },
        setImage(file) {
            const preview = URL.createObjectURL(file)

            this.image.file = file
            this.image.preview = preview
        },
        async saveUserImage(file) {
            const response = await this.$api.uploadImage(file);

            if (response.error) {
                this.$emitToastr('error', response.message);
                return
            }

            this.object.profile_picture = response.message;
        },
        async save() {
            this.saving = true;

            if (this.image.file) {
                await this.saveUserImage(this.image.file);
            }

            const response = await this.$api.updateContractor(this.object);
            this.saving = false;

            if (response.error) {
                return;
            }

            this.$emitToastr('success', 'Dados atualizados com sucesso');
        },
    },
};
</script>