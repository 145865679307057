<template>
    <div class="menu-page">
        <h1>Cursos</h1>
    </div>
</template>
  
<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "CursosView",

    data() {
        return {
        };
    },

    components: {},

    methods: {
    },
};
</script>
  
<style scoped>

</style>
  